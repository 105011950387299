.font-inter-regular {
  font-family: Inter-Regular;
}

.font-inter-medium {
  font-family: Inter-Medium;
}

.font-inter-light {
  font-family: Inter-Light;
}

.font-inter-bold {
  font-family: Inter-Bold;
}

.font-inter-thin {
  font-family: Inter-Thin;
}

.font-inter-semibold {
  font-family: Inter-SemiBold;
}

@font-face {
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Regular'), url('../fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Medium'), url('../fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Light'), url('../fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Bold'), url('../fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Thin'), url('../fonts/Inter-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Thin'), url('../fonts/Inter-SemiBold.ttf') format('truetype');
}

.tooltip {
  border-radius: 7px !important;
  font-weight: lighter !important;
}

.zoom {
  padding: 0px;
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.09);
}

.pagination {
  margin: 15px 10px 10px 10px;
  display: flex;
  list-style: none;
  outline: none;
  font-size: 12px;
}

.pagination > .active > a {
  background-color: #5e72e4;
  color: #fff;
  font-size: 12px;
}
.pagination > li > a {
  border: 0.5px solid #e9ecef;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  border-color: transparent;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #696868;
}
.pagination > li > a:hover {
  background-color: '#373981' !important;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.previous_page {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  font-size: 12px;
  color: #696868;
}
.previous_page:hover {
  background-color: #373981 !important;
  color: white !important;
}
.next_page {
  font-size: 12px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  color: #5e72e4 !important;
}
.next_page:hover {
  background-color: #373981 !important;
  color: white !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  display: none;
}

//***************layout style*********

:root {
  --landscape-width: 16.5; // % OR vw for responsive window resizing!
  --portrait-width: 57vw;
  --header-height: 6.3rem;
  --secs: 0.4s;
  --bg-left: white;
  --bg-right: cyan;
  --bg-main: lemonchiffon;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  // overflow: hidden; // Disable scrolling on body
}

#layout {
  display: flex; // Flexbox definition
  overflow: hidden; // Disable scrolling on layout

  // Style in common for sidebars and main area:
  .header {
    position: relative;
    height: var(--header-height);
    text-align: center; // Horizontal center
    .title {
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  .content {
    overflow: auto;
    box-sizing: border-box;
    // // height: calc(100vh - var(--header-height));
    // height: 95vh;
    // //padding-top: 1rem;
    height: calc(108vh - var(--header-height));
    padding: 1rem;
  }
}

// Main area specific style:
#main {
  flex-grow: 1;
  width: 100%;
  .header {
    @media (orientation: landscape) {
      .title {
        transition: left var(--secs), right var(--secs);
        &.left-closed {
          left: var(--header-height);
        }
        &.right-closed {
          right: var(--header-height);
        }
      }
    }
  }
}

// Style in common for both sidebars:
#left,
#right {
  transition: width var(--secs);
  width: 0;
  @media (orientation: landscape) {
    &.open {
      width: calc(1% * var(--landscape-width));
    }
  } // <-- Landscape ONLY sidebars
  .icon {
    position: fixed;
    z-index: 10;
    width: var(--header-height);
    height: var(--header-height);
    line-height: var(--header-height);
    font-size: var(--header-height);
    text-align: center;
    user-select: none;
    cursor: pointer;
  }
  .sidebar {
    box-shadow: 1px 0px 10px rgb(194, 192, 192);
    transition: transform var(--secs);
    height: 100%;
    // <-- Portrait AND landscape!
    @media (orientation: portrait) {
      width: var(--portrait-width);
    }
    @media (orientation: landscape) {
      width: calc(1vw * var(--landscape-width));
      max-width: 260px;
    }
    .header {
      width: calc(100% - var(--header-height));
    }
  }
}

// Left sidebar specific style:
#left {
  z-index: 5;
  .sidebar {
    border: #373981 !important;
    border-width: 2px !important;
    background-color: #696868;
    background: var(--bg-left);
    &.closed {
      transform: translateX(-100%);
    } // <-- Left portrait AND landscape!
    .header {
      left: var(--header-height);
    }
  }
}

//react tags
.react-tag-input {
  font-size: 13px !important; /* specific font size */
  font-family: 'Inter-Regular' !important;
}

.react-tag-input__input {
  font-size: 13px !important;
  font-family: 'Inter-Regular' !important;
}

//***************Bambora custom checkout*************
.container {
  background-color: rgb(240, 240, 240);
  margin: auto;
  width: calc(95%);

  border: none;
  border-radius: 4px;
}

#checkout-form {
  position: relative;
  width: calc(95%);
  margin: auto;
  padding: 10px;
}

#checkout-form label {
  display: block;
  min-height: 25px;

  font-size: 15px;
  font-weight: 500;
  margin: 5px 0;
  padding: 0;
  color: red;
}

#card-number,
#card-cvv,
#card-expiry {
  background-color: #fff;
  display: block;
  width: calc(90%);
  border-radius: 2px;
  border: 1px solid rgba(200, 200, 200, 1);
  padding: 14px 60px 13px 20px;
  margin: auto;
  transition: all 100ms ease-out;
}

/* card images are added to card number */
#card-number {
  background-image: none;

  background-origin: content-box;
  background-position: calc(100% + 40px) center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* buttons */
.btn {
  vertical-align: top;
}

.btn {
  position: relative;
  border: none;
  border-radius: 4px;
  background-color: rgba(120, 71, 181, 1);
  color: rgba(255, 255, 255, 1);
  display: inline-block;
  transition: all 100ms ease-out;
  padding: 11px 25px;
}

.btn:hover,
.btn:active {
  background-color: rgba(69, 36, 89, 1);
}

.btn:active {
  box-shadow: inset 0 0 35px rgba(0, 0, 0, 0.3);
}

.btn:disabled {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(120, 71, 181, 1);
  color: rgba(120, 71, 181, 1);
}

/* feedback is displayed after tokenization */
#feedback {
  position: relative;
  left: 15px;
  display: inline-block;
  background-color: transparent;
  border: 0px solid rgba(200, 200, 200, 1);
  border-radius: 4px;
  transition: all 100ms ease-out;
  padding: 11px;
}

#feedback.error {
  color: red;
  border: 1px solid;
}

#feedback.success {
  color: seagreen;
  border: 1px solid;
}

.css-19kzrtu {
  padding: 0 !important;
}
